@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --gradient-purple-blue: radial-gradient(
      100% 100% at -30% 15%,
      #3d65ff,
      transparent
    ),
    radial-gradient(100% 100% at 130% 15%, #75d1f9, transparent);
  --gradient-blue: radial-gradient(
    100% 100% at 130% 15%,
    #75d1f9,
    theme(colors.blue.50)
  );
  --gradient-dark-blue: linear-gradient(277deg, #3d2b91 0%, #256eeb 100%);
}
.inter {
  font-family: 'Inter', sans-serif;
}
body {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #4f526c;
  margin: 0;
  padding: 0;
}
.nav-items {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}
.footer-items-heading {
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 24px;
  color: #9a9cab;
}
.footer-items {
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}
